<template>
  <b-card>
      <b-row>
        <b-col md="4">
            <g-field
              :value.sync="selectedItem.currentPassword"
              type="password"
              name="cPassword"
              label-text="currentPassword"
              rules="required"
            />
        </b-col>
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.newPassword"
              type="password"
              label-text="newPassword"
              rules="required"
              name="nPassword"
              autocomplete="off"
            />
          </b-form-group>
           <password-validation
            :password="selectedItem.newPassword"
            @validPassword="updateValidPassword"
          />
        </b-col>
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.passwordConfirmation"
              type="password"
              label-text="confirmPassword"
            />
            <!-- danger -->
            <b-alert variant="danger" :show="selectedItem.passwordConfirmation !== selectedItem.newPassword">
              <div class="alert-body">
                <span>{{ $t('passwordDonotMatched') }}</span>
              </div>
          </b-alert>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="d-flex justify-content-end sticky-bottom">
          <b-button class="mx-1" @click="save" variant="primary" data-action-type="save"  :disabled="selectedItem.passwordConfirmation !== selectedItem.newPassword || !valid_password || !selectedItem.currentPassword">
            {{ $t('save') }}
          </b-button>
        </b-col>
      </b-row>
    <!-- </g-form> -->
  </b-card>
</template>

<script>
import { mapActions } from 'vuex';
import passwordValidation from './components/passwordValidation.vue';

export default {
   components: {
    passwordValidation,
  },
  data() {
    return {
       selectedItem: {
         currentPassword: '',
         newPassword: '',
         passwordConfirmation: ''
       },
       valid_password: false
    };
  },
  mounted() {
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout'
    }),
    save() {
       this.create({
            url: 'Users/change-password',
            data: this.selectedItem
          }).then(() => {
        this.doneAlert({
          text: this.$t('updatedSuccessfully')
        });
          this.logout();
      }).catch(({ data }) => {
        this.doneAlert({ text: data.detail, type: 'error' });
      });
    },
    updateValidPassword(validPass) {
      this.valid_password = validPass;
    }
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/pages/password-validation-style.scss';
</style>
